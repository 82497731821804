<template>
  <PageWrapper
    ><div class="w-full">
      <Header v-slot="{ _headerClass, _subheaderClass }">
        <h1 :class="_headerClass">Household</h1>
        <p :class="_subheaderClass">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Eligendi
          fugit enim facere aliquid, vitae sapiente distinctio voluptatem ut.
          Quae, consequuntur!
        </p>
      </Header>

      <Table>
        <template #header="{ _class }">
          <div class="grid-cols-6" :class="_class">
            <div>Relationship</div>
            <div>First Name</div>
            <div>Last Name</div>
            <div>Sex</div>
            <div>Birthday</div>
            <div>SSN</div>
          </div>
        </template>
        <template #row="{ _outerClass, _innerClass, _buttonClass }">
          <div
            :class="_outerClass"
            v-for="resident in residents"
            :key="resident.residentId"
          >
            <div class="grid-cols-6" :class="_innerClass">
              <div>
                {{ resident.relationship === "" ? "-" : resident.relationship }}
              </div>
              <div>
                {{ resident.firstName === "" ? "-" : resident.firstName }}
              </div>
              <div>
                {{ resident.lastName === "" ? "-" : resident.lastName }}
              </div>
              <div>{{ resident.sex === "" ? "-" : resident.sex }}</div>
              <div>
                {{
                  formatDOB(resident.dob) === "" ? "-" : formatDOB(resident.dob)
                }}
              </div>
              <div>{{ resident.ssn === "" ? "-" : resident.ssn }}</div>
            </div>
            <div :class="_buttonClass">
              <div class="w-6" v-if="isSelf(resident)"></div>
              <EditButton
                @click="displayEditResidentModal(resident.residentId)"
              />
              <DeleteButton
                v-if="!isSelf(resident)"
                @click="removeHouseholdMember(resident.residentId)"
              />
            </div>
          </div>
        </template>
        <template
          #col="{
            _outerClass,
            _headerOuterClass,
            _headerClass,
            _btnClass,
            _innerClass,
            _cellOuterClass,
            _cellHeaderClass,
            _cellBodyClass,
          }"
        >
          <div
            :class="_outerClass"
            v-for="resident in residents"
            :key="resident.residentId"
          >
            <div :class="_headerOuterClass">
              <h6 :class="_headerClass">{{ resident.label }}</h6>
              <div :class="_btnClass">
                <div class="w-6" v-if="isSelf(resident)"></div>
                <EditButton
                  @click="displayEditResidentModal(resident.residentId)"
                />
                <DeleteButton
                  v-if="!isSelf(resident)"
                  @click="removeHouseholdMember(resident.residentId)"
                />
              </div>
            </div>
            <div :class="_innerClass">
              <div :class="_cellOuterClass" class="border-t">
                <div :class="_cellHeaderClass">Relationship</div>
                <div :class="_cellBodyClass">
                  {{
                    resident.relationship === "" ? "-" : resident.relationship
                  }}
                </div>
              </div>
              <div :class="_cellOuterClass">
                <div :class="_cellHeaderClass">Sex</div>
                <div :class="_cellBodyClass">
                  {{ resident.sex === "" ? "-" : resident.sex }}
                </div>
              </div>
              <div :class="_cellOuterClass">
                <div :class="_cellHeaderClass">Birthday</div>
                <div :class="_cellBodyClass">
                  {{
                    formatDOB(resident.dob) === ""
                      ? "-"
                      : formatDOB(resident.dob)
                  }}
                </div>
              </div>
              <div :class="_cellOuterClass">
                <div :class="_cellHeaderClass">SSN</div>
                <div :class="_cellBodyClass">
                  {{ resident.ssn === "" ? "-" : resident.ssn }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </Table>

      <!-- Add Resident Button -->
      <button
        class="mb-4 px-1 text-blue-500 font-medium text-sm flex items-center"
        @click="displayAddResidentModal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="ai ai-PersonAdd mr-1"
        >
          <circle cx="12" cy="7" r="5" />
          <path
            d="M17 22H5.266a2 2 0 0 1-1.985-2.248l.39-3.124A3 3 0 0 1 6.649 14H7"
          />
          <path d="M19 13v6" />
          <path d="M16 16h6" /></svg
        >Add Household Member
      </button>

      <!-- Add Resident Modal -->
      <AddResident
        @hideModal="hideAddResidentModal"
        :showModal="showAddResidentModal"
      />

      <!-- Edit Resident Modal -->
      <Modal :showModal="showEditResidentModal">
        <div
          class="w-full max-w-md rounded relative bg-white shadow"
          v-click-away="hideEditResidentModal"
        >
          <div
            class="w-full border-b bg-gray-50 flex justify-between items-center p-3 rounded-t"
          >
            <div>
              <h1 class="text-sm uppercase text-gray-500 font-semibold">
                Edit Household Member
              </h1>
            </div>
            <button
              @click="hideEditResidentModal"
              class="hover:bg-gray-200 w-6 h-6 rounded flex justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="ai ai-Cross"
              >
                <path d="M20 20L4 4m16 0L4 20" />
              </svg>
            </button>
          </div>
          <div class="p-4">
            <div class="grid grid-cols-1 gap-y-4">
              <Select
                v-if="currentResident.relationship !== relationships.self"
                :label="'Relationship'"
                :placeholder="'Select relationship'"
                :options="relationshipOptions"
                :modelValue="currentResident.relationship"
                :excludeOptions="[relationships.self]"
                @select="editRelationship"
                labelClasses="font-medium text-xs mb-2"
                selectClasses="bg-gray-50 py-2 pl-4 pr-2 border border-gray-300 rounded flex justify-between items-center text-sm"
                focusClasses="bg-gray-50 border border-primary rounded"
              />
              <TextField
                label="First name"
                placeholder="Enter first name"
                :modelValue="currentResident.firstName"
                @textChange="editFirstName"
                labelClasses="font-medium text-xs mb-2"
                inputClasses="bg-gray-50 py-2 px-4 border border-gray-300 rounded text-sm"
                focusClasses="bg-gray-50 py-2 px-4 border border-primary rounded text-sm"
              />
              <TextField
                label="Last name"
                placeholder="Enter last name"
                :modelValue="currentResident.lastName"
                @textChange="editLastName"
                labelClasses="font-medium text-xs mb-2"
                inputClasses="bg-gray-50 py-2 px-4 border border-gray-300 rounded text-sm"
                focusClasses="bg-gray-50 py-2 px-4 border border-primary rounded text-sm"
              />
              <Select
                :label="'Sex'"
                :placeholder="'Select sex'"
                :options="[
                  { value: 'Male', text: 'Male' },
                  { value: 'Female', text: 'Female' },
                ]"
                :modelValue="currentResident.sex"
                @select="editSex"
                labelClasses="font-medium text-xs mb-2"
                selectClasses="bg-gray-50 py-2 pl-4 pr-2 border border-gray-300 rounded flex justify-between items-center text-sm"
                focusClasses="bg-gray-50 border border-primary rounded"
              />
              <TextField
                label="Birthday"
                placeholder="MM/DD/YYYY"
                :modelValue="formatDOB(currentResident.dob)"
                @textChange="editDOB"
                :mask="'##/##/####'"
                :pattern="'[0-9]*'"
                labelClasses="font-medium text-xs mb-2"
                inputClasses="bg-gray-50 py-2 px-4 border border-gray-300 rounded text-sm"
                focusClasses="bg-gray-50 py-2 px-4 border border-primary rounded text-sm"
              />
              <TextField
                label="SSN"
                placeholder="Enter SSN"
                :modelValue="currentResident.ssn"
                @textChange="editSSN"
                :mask="'###-##-####'"
                :pattern="'[0-9]*'"
                labelClasses="font-medium text-xs mb-2"
                inputClasses="bg-gray-50 py-2 px-4 border border-gray-300 rounded text-sm"
                focusClasses="bg-gray-50 py-2 px-4 border border-primary rounded text-sm"
              />
            </div>
          </div>
          <div
            class="border-t p-3 flex justify-between items-center bg-gray-50 rounded-b"
          >
            <button
              @click="hideEditResidentModal"
              class="py-3 font-medium text-xs w-1/2 text-blue-500"
            >
              Cancel
            </button>
            <button
              @click="editHouseholdMember"
              class="py-3 w-1/2 text-xs btn-primary rounded border font-medium"
            >
              Save Changes
            </button>
          </div>
        </div>
      </Modal>

      <div class="w-full flex justify-end items-center">
        <router-link
          :to="{ name: 'address' }"
          class="btn-secondary py-2 w-24 rounded text-sm mx-2"
        >
          Back</router-link
        ><router-link
          :to="{ name: 'interview' }"
          class="btn-primary text-sm py-2 w-24 rounded"
          >Continue</router-link
        >
      </div>
    </div>
  </PageWrapper>
</template>

<script>
import { reactive, toRefs } from "@vue/reactivity";
import { relationshipOptions, relationships } from "../../common/constants";
import { formatDOB, isSelf } from "../../utils/functions";
import useBenefitRecord from "../../composables/useBenefitRecord";
import PageWrapper from "../../components/home/layout/PageWrapper.vue";
import Modal from "../../components/common/ui/Modal.vue";
import TextField from "../../components/common/ui/TextField.vue";
import Select from "../../components/common/ui/Select.vue";
import AddResident from "../../components/home/ui/AddResident.vue";
import Table from "../../components/home/ui/Table.vue";
import EditButton from "../../components/home/ui/EditButton.vue";
import DeleteButton from "../../components/home/ui/DeleteButton.vue";
import Header from "../../components/home/ui/Header.vue";
export default {
  components: {
    PageWrapper,
    Modal,
    TextField,
    Select,
    AddResident,
    Table,
    EditButton,
    DeleteButton,
    Header,
  },
  setup() {
    const {
      residents,
      editResident,
      saveBenefitRecord,
      removeResident,
    } = useBenefitRecord();
    const state = reactive({
      showAddResidentModal: false,
      showEditResidentModal: false,
      currentResident: null,
    });

    const displayAddResidentModal = () => {
      state.showAddResidentModal = true;
    };

    const hideAddResidentModal = () => {
      state.showAddResidentModal = false;
    };

    const displayEditResidentModal = (residentId) => {
      state.currentResident = {};
      const editResident = residents.value.find(
        (resident) => resident.residentId === residentId
      );
      // We want to copy it; not reference it, so they can cancel the edit if they need to
      Object.assign(state.currentResident, editResident);
      state.showEditResidentModal = true;
    };

    const hideEditResidentModal = () => {
      state.currentResident = null;
      state.showEditResidentModal = false;
    };

    const editRelationship = (relationship) => {
      state.currentResident.relationship = relationship;
    };

    const editFirstName = (firstName) => {
      state.currentResident.firstName = firstName;
    };

    const editLastName = (lastName) => {
      state.currentResident.lastName = lastName;
    };

    const editSex = (sex) => {
      state.currentResident.sex = sex;
    };

    const editDOB = (dob) => {
      state.currentResident.dob = dob;
    };

    const editSSN = (ssn) => {
      state.currentResident.ssn = ssn;
    };

    const editHouseholdMember = async () => {
      editResident(state.currentResident);
      await saveBenefitRecord();
      hideEditResidentModal();
    };

    const removeHouseholdMember = async (residentId) => {
      await removeResident(residentId);
    };

    return {
      residents,
      relationshipOptions,
      relationships,
      formatDOB,
      ...toRefs(state),
      displayAddResidentModal,
      hideAddResidentModal,
      displayEditResidentModal,
      hideEditResidentModal,
      editRelationship,
      editFirstName,
      editLastName,
      editSex,
      editDOB,
      editSSN,
      editHouseholdMember,
      removeHouseholdMember,
      isSelf,
    };
  },
};
</script>
